export default {
    "web": {
        "header": {
            "title": "Ecco cosa ti aspetta durante la simulazione dei tuoi risparmi:",
            "list": [
                "Ti chiederemo alcuni dati sulla tua casa, sulle fonti di energia che utilizzi e sui tuoi consumi.",
                "Analizzeremo questi dati per fornirti un report sulle tue opportunità di risparmio e proporti delle soluzioni che potrai configurare come credi sia meglio per te.",
                "Potrai lasciarci i tuoi contatti per ricevere il report e parlare con un nostro consulente per approfondire il tuo piano di risparmio."
            ]
        },
        "body": {
            "title": " Il tuo percorso verso il risparmio energetico parte da qui!",
            "ocrLoader": {
                "title": "Iniziamo dalla bolletta",
                "subtitle": "<span class=\"ocr-strong\">Pensiamo a tutto noi!</span> Carica la tua bolletta e <span class=\"ocr-strong\">recupereremo automaticamente i dati</span> necessari per avviare la simulazione. <span class=\"ocr-strong\">Puoi stare tranquillo:</span> le informazioni che ci fornirai verranno utilizzate <span class=\"ocr-strong\">esclusivamente per questa simulazione</span>, e i dati sensibili, come il <span class=\"ocr-strong\">codice POD</span>, non verranno raccolti dalla bolletta.",
                "afterText": "Procedendo con la simulazione, potrai verificare e modificare tutti i dati recuperati dalla tua bolletta, se lo ritieni necessario."
            },
            "ocrDropzone": {
                "text": "Trascina la bolletta per recuperare i tuoi dati oppure"
            },
            "ocrNoBill": {
                "title": "Non hai una bolletta recente sotto mano?",
                "text": "Non c'è assolutamente alcun problema. <span class=\"ocr-strong\">Potrai inserire manualmente tutti i dati necessari</span>."
            },
            "ocrResults": {
                "title": "Abbiamo recuperato alcuni dati per te!"
            }
        }
    },
    "negozi": {
        "header": {
            "title": "Ecco cosa farai durante la simulazione con il cliente:",
            "list": [
                "Chiedi dettagli sulla <span class=\"ocr-strong\">casa</span> del cliente, sulle fonti di <span class=\"ocr-strong\">energia</span> che utilizza e sui suoi <span class=\"ocr-strong\">consumi</span> attuali. Usa un tono amichevole per mettere il cliente a proprio agio e ottenere dati più precisi.",
                "Spiega al cliente che i dati inseriti verranno analizzati per generare un <span class=\"ocr-strong\">report personalizzato con le opportunità di risparmio</span>. Presenta le soluzioni proposte e discuti con il cliente come configurarle in base alle sue esigenze.",
                "<span class=\"ocr-strong\">Invita il cliente a lasciare i propri contatti</span> per ricevere il <span class=\"ocr-strong\">report via email</span>. Spiega che, se necessario in futuro, un consulente sarà disponibile per approfondire il piano di risparmio e valutare le possibili soluzioni."
            ]
        },
        "body": {
            "title": " Carica la bolletta del cliente:",
            "ocrLoader": {
                "subtitle": "<span class=\"ocr-strong\">Rassicura il cliente</span> che dalla bolletta verranno raccolte solo <span class=\"ocr-strong\">le informazioni utili per la simulazione</span>, come la potenza del contatore e il consumo annuo, <span class=\"ocr-strong\">senza essere salvate</span>. I dati sensibili, <span class=\"ocr-strong\">come il codice POD</span>, non verranno né raccolti né memorizzati.",
                "afterText": "Procedendo con la simulazione, assieme al cliente potrete verificare e modificare tutti i dati recuperati dalla tua bolletta, se sarà necessario."
            },
            "ocrDropzone": {
                "text": "Trascina la bolletta per recuperare i dati del cliente oppure"
            },
            "ocrNoBill": {
                "title": "Se il cliente non ha una bolletta recente",
                "text": "Fai sapere al cliente che può comunque procedere. In tal caso, inserirai manualmente i dati necessari basandoti sulle informazioni fornite da lui. "
            },
            "ocrResults": {
                "title": "Abbiamo recuperato alcuni dati per il tuo cliente!",
                "subtitle": "Elenca al cliente i dati che avete recuperato dalla sua bolletta per rassicurarlo che non sono stati raccolti dati sensibili come il codice POD o altre informazioni che non voleva condividere. "
            }
        }
    }
}

import { configureStore } from '@reduxjs/toolkit';
import dataReducer from '../reducers/data.js';
import ocrReducer from '../components/ocr/ocr-reducer.js'
import storage from 'redux-persist/lib/storage';
// import { persistReducer, persistStore } from 'redux-persist';
// import thunk from 'redux-thunk';

// const store = configureStore({
//   reducer: {
//     data: dataReducer,
//   },
// });

// const persistConfig = {
//   key: 'root',
//   storage,
// }

//const persistedReducer = persistReducer(persistConfig, dataReducer)

const multiActionMiddleware = store => next => action => {
  if (Array.isArray(action)) {
    console.log("Multi action middleware")
    action.forEach(a => store.dispatch(a));
    return;
  }
  return next(action);
};

export const store = configureStore({
    reducer: {
      data: dataReducer,
      ocr: ocrReducer,
    },
    middleware: (getDefaultMiddleware) => 
      getDefaultMiddleware().concat(multiActionMiddleware)
  });
  
// export const storePersisted = configureStore({
//   reducer: { data: persistedReducer },
//   // devTools: process.env.NODE_ENV !== 'production',
//   // middleware: [thunk]
// })

// export const persistor = persistStore(store)
// // export const store;


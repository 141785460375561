import { createSlice } from '@reduxjs/toolkit';
import formTemplate from "../utils/form"

const fields = formTemplate.pages.reduce((prev, curr) => [...curr.items, ...prev] , [])

const numerical = ["slider", "number" ]

const obj = {...fields.filter(x => x.cell).reduce((prev, curr) => {
  if (numerical.includes(curr.type)){
    prev[curr.cell] = curr.min ?? 0
  }
  else if (curr.type === "optional"){
    prev[curr.cell] = false
  }
  else if (curr.type === "bool"){
    prev[curr.cell] = false
  }
  else if (curr.type === "choice"){
    prev[curr.cell] = ""
  }
  else {
    prev[curr.cell] = ""
  }
  return prev
}, {})}

const testData = {
  C13: '20',
  C16: 1,
  C15: 1,
  C17: 12,
  C35: 0,
  C34: "Si",
  D61: 3,
  C61: "Si",
  C48Test: false,
  C78: "Non so",
  image: "landing",
  PrestazioneRiscaldamento: "Miglior rapporto qualità/prezzo €€",
  PrestazionePennelli: "Miglior rapporto qualità/prezzo €€",
  User_TV: "Samsung Crystal UHD 55\""
}

const testDataEnelIT = {
  "ProjectUrl": 'https://greenovation.blob.core.windows.net/enelcore/22598.pdf',
  "negozi": false,
  "UserID": 22598,
  "foto_prices": {
    "acc-eco": 9890.0,
    "acc-mid": 11390.0,
    "acc-top": 11490.0,
    "ftv-eco": 4500.0,
    "ftv-mid": 5840.0,
    "ftv-top": 8490.0
},
  "products": [
      {
          "name": "Clima",
          "modello": "Comfee Clima 9 + 9 + 12 btu/h",
          "costo": "",
          "scontato": "1.999",
          "ecobonus": "",
          "link": "https://www.enelxstore.com/it/it/prodotti/climatizzatori/wall-air-conditioners/multi-split/comfee-9-9-12-btu-h-3fb-27k-cfw09a-x2-12a-by-midea",
          "qty": 1
      },
      {
          "name": "Lavatrice",
          "modello": "BESPOKE AI - EcoDosatore",
          "costo": "",
          "scontato": "1.000",
          "ecobonus": "",
          "link": null,
          "qty": 1
      },
      {
          "name": "Asciugatrice",
          "modello": "BESPOKE AI - Silent Dry",
          "costo": "",
          "scontato": "900",
          "ecobonus": "",
          "link": null,
          "qty": 1
      },
      {
          "name": "TV",
          "modello": "Crystal UHD 43\"",
          "costo": "",
          "scontato": "600",
          "ecobonus": "",
          "link": null,
          "qty": 1
      },
      {
          "name": "Fotovoltaico",
          "modello": "Enel SunStorage Black Large 4 KWp",
          "costo": "",
          "scontato": "13.990",
          "ecobonus": "",
          "link": null,
          "qty": 1
      },
      {
          "name": "Frigo",
          "modello": "Frigorifero Combinato F1rst 75 AI",
          "costo": "",
          "scontato": "1.200",
          "ecobonus": "",
          "link": null,
          "qty": 1
      }
  ],
  "clima_prices": {
    "1 motore mono split-eco": 1089.0,
    "1 motore mono split-mid": 1195.0,
    "1 motore mono split-top": 1615.0,
    "1 motore dual split-eco": 2120.0,
    "1 motore dual split-mid": 2120.0,
    "1 motore dual split-top": 2729.0,
    "2 motori mono split-eco": 2178.0,
    "2 motori mono split-mid": 2390.0,
    "2 motori mono split-top": 3230.0,
    "1 motore trial split-eco": 2999.0,
    "1 motore trial split-mid": 2999.0,
    "1 motore trial split-top": 3965.0,
    "3 motori mono split-eco": 3267.0,
    "3 motori mono split-mid": 3585.0,
    "3 motori mono split-top": 4845.0,
    "2 motori dual split-eco": 4240.0,
    "2 motori dual split-mid": 4240.0,
    "2 motori dual split-top": 5458.0,
    "2 motori mono split + 1 dual split-eco": 4298.0,
    "2 motori mono split + 1 dual split-mid": 4510.0,
    "2 motori mono split + 1 dual split-top": 5959.0,
    "1 motore dual split + 1 trial split-eco": 5119.0,
    "1 motore dual split + 1 trial split-mid": 5119.0,
    "1 motore dual split + 1 trial split-top": 6694.0,
    "1 motore mono split + 2 dual split-eco": 5329.0,
    "1 motore mono split + 2 dual split-mid": 5435.0,
    "1 motore mono split + 2 dual split-top": 7073.0,
    "2 motori trial split-eco": 5998.0,
    "2 motori trial split-mid": 5998.0,
    "2 motori trial split-top": 7930.0,
    "3 motori dual split-eco": 6360.0,
    "3 motori dual split-mid": 6360.0,
    "3 motori dual split-top": 8187.0,
    "1 motore mono split + 2 motori trial split-eco": 7087.0,
    "1 motore mono split + 2 motori trial split-mid": 7193.0,
    "1 motore mono split + 2 motori trial split-top": 9545.0,
    "1 motore dual split + 2 motori trial split-eco": 8118.0,
    "1 motore dual split + 2 motori trial split-mid": 8118.0,
    "1 motore dual split + 2 motori trial split-top": 10659.0,
    "3 motori trial split-eco": 8997.0,
    "3 motori trial split-mid": 8997.0,
    "3 motori trial split-top": 11895.0
},
"negozi": false,
  "ConsumoOrario": true,
  "EnelCoreModelId": 13926,
      "PreviousEnelCoreModelId": 0,
      "CreateDate": "2024-07-24T08:34:23.2862996+00:00",
      "ExecutionStart": null,
      "Email": null,
      "pageIndex":3,
      "C10": "Non so",
      "C11": "Monofamiliare",
      "C12": "",
      "C13": "150",
      "C14": "Milano",
      "C15": "2",
      "C16": "3",
      "C17": "12",
      "C18": "Si",
      "C19": "No",
      "C24": "No",
      "C25": "Metano",
      "F25": "",
      "F26": "0",
      "F27": "0",
      "C26": "Metano",
      "C27": "Metano",
      "C28": null,
      "C29": "No",
      "C30": null,
      "C31": "No",
      "C32": null,
      "C33": "",
      "C34": "No",
      "C35": "",
      "C36": "G",
      "C41": "Si",
      "C42": "1",
      "C45": "No",
      "C46": "1",
      "C47": null,
      "C48": "No",
      "C53": "No",
      "C54": "Si",
      "D61": "3",
      "C61": "No",
      "C62": null,
      "C66": null,
      "C67": null,
      "C68": null,
      "C69": null,
      "C70": null,
      "C71": null,
      "C78": "Non so",
      "C79": null,
      "C82": "2",
      "C84": "No",
      "D84": "",
      "D41": "LG ATMOSFERA Trialsplit 9+9+12 btu/h",
      "E41": null,
      "F41": null,
      "D46": null,
      "E46": null,
      "F46": null,
      "D48": "No",
      "D53": "Enel Sun Medium 3 kWp FORMIDABILE",
      "Risc_Attuale": "1924.43266193845",
      "Risc_Futura": "1895.42318969877",
      "Risc_Risparmio": "-30",
      "Cott_Attuale": "57.960120872383",
      "Cott_Futura": "0",
      "Cott_Risparmio": "-57.960120872383",
      "AC_Attuale": null,
      "AC_Futura": null,
      "TipologiaBalconi":"Muratura",
      "AC_Risparmio": null,
      "Foto_Risparmio": "-221",
      "Foto_Perc": "-0.3",
      "PresenzaFotovoltaico": "No",
      "PresenzaBalcone": "Si",
      "SpesaAnnuaAttuale": "2.568€",
      "SpesaAnnuaFutura": "1.267 €",
      "RisparmioAnnuo": "1301",
      "CO2": "2.003 kg",
      "Alberi": "111 alberi",
      "Investimento": "17.889 €",
      "RecuperoTotale": "+10",
      "RisparmioPerc": 0.50643169705163116,
      "PrestazionePennelli": "Top di gamma €€€",
      "PrestazioneRiscaldamento": "Più conveniente",
      "PrestazioneCottura": null,
      "NumeroClimatizzatori": "3",
      "CRMID": null,
      "extraco2": null,
      "Frigorifero": "Si",
      "Frigorifero_Risparmio": "47.69",
      "Frigorifero_Attuale": "63.34",
      "Frigorifero_Futura": "15.65",
      "Lavatrice": "Si",
      "Lavatrice_Risparmio": "11.43",
      "Lavatrice_Attuale": "22.85",
      "Lavatrice_Futura": "11.43",
      "Asciugatrice": "Si",
      "Asciugatrice_Risparmio": "69.55",
      "Asciugatrice_Attuale": "111.29",
      "Asciugatrice_Futura": "41.73",
      "TV": "Si",
      "TV_Risparmio": "32.29",
      "TV_Attuale": "86.20",
      "TV_Futura": "53.90",
      "User_TV": "Crystal UHD 43\"",
      "User_Frigo": "Frigorifero Combinato F1rst 75 AI",
      "Final_TV": "Si",
      "Final_Asciugatrice": "Si",
      "Final_Frigorifero": "Si",
      "Final_Lavatrice": "Si"
}

const testDataMottu = {
  "products": [
      {
          "name": "Lavatrice",
          "modello": "Samsung Lavatrice Serie 6400D Ai Control EcoDosatore",
          "costo": "720",
          "scontato": "720",
          "ecobonus": "",
          "link": null,
          "qty": 1
      },
      {
          "name": "Asciugatrice",
          "modello": "Asciugatrice Samsung BESPOKE AI - Silent Dry",
          "costo": "888",
          "scontato": "888",
          "ecobonus": "",
          "link": null,
          "qty": 1
      },
      {
          "name": "Frigo",
          "modello": "Frigorifero Combinato Samsung BESPOKE AI",
          "costo": "1.104",
          "scontato": "1.104",
          "ecobonus": "",
          "link": null,
          "qty": 1
      }
  ],
      "EnelCoreModelId": 28487,
      "PreviousEnelCoreModelId": 0,
      "CreateDate": "2025-04-01T13:03:23.2666443+00:00",
      "ExecutionStart": null,
      "Email": null,
      "ProjectUrl": "https://greenovation.blob.core.windows.net/enelcore/28487.pdf",
      "C10": "1919-1945 e non ristrutturata di recente",
      "C11": "Bifamiliare",
      "C12": "",
      "C13": "130",
      "C14": "Roma",
      "C15": "4",
      "C16": "5",
      "C17": "12",
      "C18": "Si",
      "C19": "No",
      "C24": "No",
      "C25": "GPL",
      "F25": "0",
      "F26": "0",
      "F27": "0",
      "C26": "GPL",
      "C27": "GPL",
      "C28": null,
      "C29": "No",
      "C30": null,
      "C31": "No",
      "C32": null,
      "C33": null,
      "C34": "Si",
      "C35": "",
      "C36": "F",
      "C41": "No",
      "C42": null,
      "C45": "No",
      "C46": "2",
      "C47": null,
      "C48": "No",
      "C53": "No",
      "C54": null,
      "D61": "3",
      "C61": "Si",
      "C62": null,
      "C66": null,
      "C67": null,
      "C68": null,
      "C69": null,
      "C70": null,
      "C71": null,
      "C78": "Non so",
      "C79": null,
      "C82": "3",
      "C84": "No",
      "D84": "",
      "D41": "LG ATMOSFERA Dualsplit 9+12 btu/h",
      "E41": "LG ATMOSFERA Trialsplit 9+9+12 btu/h",
      "F41": null,
      "D46": null,
      "E46": null,
      "F46": null,
      "D48": null,
      "D53": "Enel Sun Edition Small",
      "Risc_Attuale": "2267.0773902439",
      "Risc_Futura": "1588.16608995888",
      "Risc_Risparmio": "-679",
      "Cott_Attuale": "139.943048780488",
      "Cott_Futura": "111.823754174955",
      "Cott_Risparmio": "-28.1192946055327",
      "AC_Attuale": null,
      "AC_Futura": null,
      "AC_Risparmio": null,
      "Foto_Risparmio": "-224",
      "Foto_Perc": "-0.222118582191868",
      "PresenzaFotovoltaico": "No",
      "PresenzaBalcone": "Si",
      "SpesaAnnuaAttuale": "3.322€",
      "SpesaAnnuaFutura": "3.211 €",
      "RisparmioAnnuo": "-111",
      "CO2": "0 kg",
      "Alberi": "0 alberi",
      "Investimento": "2.711 €",
      "RecuperoTotale": "+10",
      "RisparmioPerc": 0.033370343543046341,
      "PrestazionePennelli": "Miglior rapporto qualità/prezzo €€",
      "PrestazioneRiscaldamento": "Miglior rapporto qualità/prezzo €€",
      "PrestazioneCottura": null,
      "NumeroClimatizzatori": null,
      "CRMID": null,
      "extraco2": null,
      "Frigorifero": "Si",
      "Frigorifero_Risparmio": "61,43",
      "Frigorifero_Attuale": "80,33",
      "Frigorifero_Futura": "18,90",
      "Lavatrice": "Si",
      "Lavatrice_Risparmio": "24,29",
      "Lavatrice_Attuale": "38,09",
      "Lavatrice_Futura": "13,80",
      "Asciugatrice": "Si",
      "Asciugatrice_Risparmio": "100,13",
      "Asciugatrice_Attuale": "150,53",
      "Asciugatrice_Futura": "50,40",
      "TV": null,
      "TV_Risparmio": null,
      "TV_Attuale": null,
      "TV_Futura": null,
      "User_TV": "Samsung Crystal UHD 55\"",
      "User_Frigo": "Frigorifero Combinato Samsung BESPOKE AI",
      "Final_TV": null,
      "Final_Asciugatrice": "Si",
      "Final_Frigorifero": "Si",
      "Final_Lavatrice": "Si",
      "negozi": true,
      "clima_prices": {
          "1 motore mono split-eco": 1079.0,
          "1 motore mono split-mid": 1079.0,
          "1 motore mono split-top": 1515.0,
          "1 motore dual split-eco": 2015.0,
          "1 motore dual split-mid": 2015.0,
          "1 motore dual split-top": 2529.0,
          "2 motori mono split-eco": 2158.0,
          "2 motori mono split-mid": 2158.0,
          "2 motori mono split-top": 3030.0,
          "1 motore trial split-eco": 1919.0,
          "1 motore trial split-mid": 1919.0,
          "1 motore trial split-top": 3665.0,
          "3 motori mono split-eco": 3237.0,
          "3 motori mono split-mid": 3237.0,
          "3 motori mono split-top": 4545.0,
          "2 motori dual split-eco": 4030.0,
          "2 motori dual split-mid": 4030.0,
          "2 motori dual split-top": 5058.0,
          "2 motori mono split + 1 dual split-eco": 4173.0,
          "2 motori mono split + 1 dual split-mid": 4173.0,
          "2 motori mono split + 1 dual split-top": 5559.0,
          "1 motore dual split + 1 trial split-eco": 3934.0,
          "1 motore dual split + 1 trial split-mid": 3934.0,
          "1 motore dual split + 1 trial split-top": 6194.0,
          "1 motore mono split + 2 dual split-eco": 5109.0,
          "1 motore mono split + 2 dual split-mid": 5109.0,
          "1 motore mono split + 2 dual split-top": 6573.0,
          "2 motori trial split-eco": 3838.0,
          "2 motori trial split-mid": 3838.0,
          "2 motori trial split-top": 7330.0,
          "3 motori dual split-eco": 6045.0,
          "3 motori dual split-mid": 6045.0,
          "3 motori dual split-top": 7587.0,
          "1 motore mono split + 2 motori trial split-eco": 4917.0,
          "1 motore mono split + 2 motori trial split-mid": 4917.0,
          "1 motore mono split + 2 motori trial split-top": 8845.0,
          "1 motore dual split + 2 motori trial split-eco": 5853.0,
          "1 motore dual split + 2 motori trial split-mid": 5853.0,
          "1 motore dual split + 2 motori trial split-top": 9859.0,
          "3 motori trial split-eco": 5757.0,
          "3 motori trial split-mid": 5757.0,
          "3 motori trial split-top": 10995.0
      },
      "pageIndex": 100,
      "foto_prices": {
          "acc-eco": 14090.0,
          "acc-mid": 14090.0,
          "acc-top": 18490.0,
          "ftv-eco": 6490.0,
          "ftv-mid": 8590.0,
          "ftv-top": 11490.0
       }
}


const initialState = {
  ...obj,
  ...testData
  };

export const counterSlice = createSlice({
  name: 'data',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    set: (state, action) => {
      // console.log(action)
      if (action.payload.cell === 'ProjectUrl' && (!action.payload || !action.payload.value || action.payload.value === '')) 
          return;        
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes

      state[action.payload.cell] = action.payload.value;
      return state
    },
    reset: (state, action) => {
      // state = { ...testData, ProjectUrl: state.ProjectUrl, negozi: state.negozi }
      return state
    }
  },
});
export const { set, reset } = counterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state) => state.counter.value;
export const get = (state) => state.data

export default counterSlice.reducer;
const testDataEnelFotoV = {
  "pageIndex":3,
  "ProjectUrl": "",
  "C10": "Non so",
  "C11": "Monofamiliare",
  "C12": "",
  "C13": "150",
  "C14": "Milano",
  "C15": "2",
  "C16": "3",
  "C17": "12",
  "C18": "Si",
  "C19": "No",
  "C24": "No",
  "C25": "Metano",
  "F25": "",
  "F26": "0",
  "F27": "0",
  "C26": "Metano",
  "C27": "Metano",
  "C28": null,
  "C29": "No",
  "C30": null,
  "C31": "No",
  "C32": null,
  "C33": "",
  "C34": "No",
  "C35": "",
  "C36": "G",
  "C41": "Si",
  "C42": "1",
  "C45": "No",
  "C46": "1",
  "C47": null,
  "C48": "No",
  "C53": "No",
  "C54": "Si",
  "D61": "7",
  "C61": "No",
  "C62": null,
  "C66": null,
  "C67": null,
  "C68": null,
  "C69": null,
  "C70": null,
  "C71": null,
  "C78": "Non so",
  "C79": null,
  "C82": "4.5",
  "C84": "No",
  "D84": "",
  "D41": "LG ATMOSFERA Trialsplit 9+9+12 btu/h",
  "E41": null,
  "F41": null,
  "D46": null,
  "E46": null,
  "F46": null,
  "D48": "No",
  "D53": "Enel Sun Medium 3 kWp FORMIDABILE",
  "Risc_Attuale": "1924.43266193845",
  "Risc_Futura": "1895.42318969877",
  "Risc_Risparmio": "-30",
  "Cott_Attuale": "57.960120872383",
  "Cott_Futura": "0",
  "Cott_Risparmio": "-57.960120872383",
  "AC_Attuale": null,
  "AC_Futura": null,
  "AC_Risparmio": null,
  "Foto_Risparmio": "-221",
  "Foto_Perc": "-0.3",
  "PresenzaFotovoltaico": "No",
  "PresenzaBalcone": "No",
  "SpesaAnnuaAttuale": "2.568€",
  "SpesaAnnuaFutura": "1.267 €",
  "RisparmioAnnuo": "-1301",
  "CO2": "2.003 kg",
  "Alberi": "111 alberi",
  "Investimento": "17.889 €",
  "RecuperoTotale": "+10",
  "RisparmioPerc": 0.50643169705163116,
  "PrestazionePennelli": "Top di gamma €€€",
  "PrestazioneRiscaldamento": "Più conveniente",
  "PrestazioneCottura": null,
  "NumeroClimatizzatori": "3",
  "CRMID": null,
  "extraco2": null,
  "Frigorifero": "Si",
  "Frigorifero_Risparmio": "47.69",
  "Frigorifero_Attuale": "63.34",
  "Frigorifero_Futura": "15.65",
  "Lavatrice": "Si",
  "Lavatrice_Risparmio": "11.43",
  "Lavatrice_Attuale": "22.85",
  "Lavatrice_Futura": "11.43",
  "Asciugatrice": "Si",
  "Asciugatrice_Risparmio": "69.55",
  "Asciugatrice_Attuale": "111.29",
  "Asciugatrice_Futura": "41.73",
  "TV": "Si",
  "TV_Risparmio": "32.29",
  "TV_Attuale": "86.20",
  "TV_Futura": "53.90",
  "User_TV": "Crystal UHD 43\"",
  "User_Frigo": "Frigorifero Combinato F1rst 75 AI",
  "Final_TV": "Si",
  "Final_Asciugatrice": "Si",
  "Final_Frigorifero": "Si",
  "Final_Lavatrice": "Si"
}

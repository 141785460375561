import { createSlice } from '@reduxjs/toolkit'
import { capitalizeFirstLetter } from '../../utils/functions';

/**
 * 7.1.2. Oggetti restituiti al chiamante
 * I dati restituiti al chiamante per l'istanza italiana di Document Intelligence sono stati estesi, il json restituito dalla callback riflette direttamente le
 * modifiche apportate alle entità che mappano i dati restituiti da Form Recognizer.
 * Questa è la struttura dati restituita ai chiamanti che utilizzano l'istanza di Document Intelligence italiana:
 * {
 *   "name": "<Nome cliente>",
 *   "surname": "<Cognome cliente>",
 *   "fiscalCode": "<Codice fiscale cliente>",
 *   "podPdr": "<Pod Pdr>",
 *   "capSupply": "<CAP fornitura>",
 *   "citySupply": "<Città fornitura>",
 *   "addressSupply": "<Indirizzo fornitura>",
 *   "capBilling": "<CAP fatturazione cliente>",
 *   "cityBilling": "<Città fatturazione cliente>",
 *   "addressBilling": "<Indirizzo fatturazione cliente>",
 *   "actualSupplier": "<Fornitore del servizio>",
 *   "powerSupply": "<Potenza erogata fornitura>",
 *   "streetNumSupply": "<Numero civico fatturazione cliente>",
 *   "streetNumBilling": "<Numero civico fornitura>",
 *   "transactionId": "<Identificativo della transazione>",
 *   "companyName": "<composto da nome + cognome>",
 *   "PIVA": "<uguale a fiscalCode>",
 *   "referenceMarket": "<Mercato di riferimento, ad esempio: mercato libero, mercato di assistenza, Servizio di tutela, Fornitura di ultima istanza>",
 *   "customerType": "<Tipo fornitura. ad esempio: cliente domestico, domestico, domestico residente, non residente, affittuario, altri usi>",
 *   "supplyMonthlyAverageConsumption": "<Media mensile dei consumi fornitura>",
 *   "supplyAnnualEstimatedConsumption": "<Stima annuale dei consumi fornitura>",
 *   "supplyGasUsage": "<Utilizzo gas fornitura>",
 *   "supplyAnnualActualConsumptionLast12M": "<Consumo negli ultimi 12 mesi>"
 * }
 */


const ocrDataTransform = (key, value) => {
  if (!value || value === "") {
    return value;
  }
  switch (key) {
    case "C14":
    case "citySupply":
      return capitalizeFirstLetter(`${value}`.toLocaleLowerCase())
    case "D61":
    case "powerSupply":
      return parseFloat(value)
    case "C35":
    case "supplyAnnualActualConsumptionLast12M":
      return parseInt(value.replace(/\D/g, ''), 10);
    default:
      return value;
  }
}


const emptyState = {
  fileInfo: null,
  transactionId: null,
  isUploading: false,
  result: null,
  isLoading: false,
  error: null,
  fieldMapping: {},
  estimatedFileds: {},
  fieldDefinition: [
    /* 
      Title: è il titolo che mostro quanto recupero i dati da ocr
      key: la chiave dell'oggetto che ritorna l'OCR
      field: è il campo da popolare nel simulatore
      unit: unità di misura (opzionale)
      mapFn: funzione per mappare il valore (opzionale) di default è il valore stesso
    */
    { title: "Città", key: "citySupply", field: "C14", order: 3},
    { title: "CAP", key: "capSupply", field: "CAP", order: 4},
    { title: "Potenza del contatore", key: "powerSupply", unit: "kW", field: "D61", order: 5},
    {
      title: "Consumo elettrico annuo",
      key: "supplyAnnualActualConsumptionLast12M",
      field: "C35",
      unit: "kWh",
      order: 6
    },
    { title: "Costo della fornitura elettrica", key: "electricityCostField", unit: "€", field: "C33", order: 7 },
    { title: "Nome", key: "name", field: "Nome" , order: 1},
    { title: "Cognome", key: "surname", field: "Cognome", order: 2 },
    ],

    otherfieldDefinition: [
    /*
      Campi dell'OCR che mappiamo ma dei quali non ce ne facciamo nulla
    */
    { key: "fiscalCode", title: "Codice fiscale cliente" },
    { key: "podPdr", title: "Pod Pdr" },
    { key: "addressSupply", title: "Indirizzo fornitura" },
    { key: "capBilling", title: "CAP fatturazione cliente" },
    { key: "cityBilling", title: "Città fatturazione cliente" },
    { key: "addressBilling", title: "Indirizzo fatturazione cliente" },
    { key: "actualSupplier", title: "Fornitore del servizio" },
    { key: "streetNumSupply", title: "Numero civico fatturazione cliente" },
    { key: "streetNumBilling", title: "Numero civico fornitura" },
    { key: "companyName", title: "Nome + Cognome" },
    { key: "PIVA", title: "P.IVA (uguale al codice fiscale)" },
    { key: "referenceMarket", title: "Mercato di riferimento" },
    { key: "customerType", title: "Tipo fornitura" },
    { key: "supplyMonthlyAverageConsumption", title: "Media mensile dei consumi fornitura", unit: "€" },
    { key: "supplyAnnualEstimatedConsumption", title: "Stima annuale dei consumi fornitura", unit: "€" },
    { key: "supplyGasUsage", title: "Utilizzo gas fornitura" }
  ]
}

const ocrTestData = {
  fieldMapping: {
    "C14": "Milano",
    "CAP": "20100",
    "Name": "Giuseppe",
    "Cognome": "Rossi",
    "D61": 5,
    "C33": "900"
  }
}

const initialState = {
  ...emptyState,
  //...ocrTestData
}

const ocrSlice = createSlice({
  name: 'ocr',
  initialState,
  reducers: {
    setFileInfo(state, action) {
      state.fileInfo = action.payload;
    },
    setTransactionId(state, action) {
      state.transactionId = action.payload;
    },
    setUploading(state, action) {
      state.isUploading = action.payload;
    },
    setOcrResult(state, action) {
      const res = {};
      Object.keys(action.payload).forEach((key) => {
        res[key] = ocrDataTransform(key, action.payload[key])
      })
      state.result = res;

    },
    setLoading(state, action) {
      state.isLoading = action.payload
    },
    setError(state, action) {
      state.error = action.payload
    },
    /**
     * Field mapping e il contenitore dei campi trovati da OCR
     * Serve a capire nel simulatore se marcare un campo come "recuperato dalla bolletta"
     */
    setFieldMapping(state) {
      if (!state.result) {
        return
      }

      state.fieldMapping = state.fieldDefinition
        .filter((fd) => !!state.result[fd.key] && state.result[fd.key] !== "")
        .reduce((acc, fd) => {
          return { ...acc, [fd.field]: state.result[fd.key] }
        }, {});

      if (!state.fieldMapping["C33"] && !!state.fieldMapping["C35"]) {
        state.estimatedFileds["C33"] = parseInt(Math.ceil(state.fieldMapping["C35"] * 0.30));
      }

    },
    unsetField(state, action) {
      if (!action || !action.payload || !action.payload.field || !state.fieldMapping) { 
        return
      }
      state.fieldMapping[action.payload.field] = null;
    },
    resetOcr(state) {
      return initialState;
    }
  }
})

// Selectors
export const selectFileInfo = (state) => state.ocr.fileInfo;
export const selectTransactionId = (state) => state.ocr.transactionId;
export const selectIsUploading = (state) => state.ocr.isUploading;
export const selectResult = (state) => state.ocr.result;
export const selectIsLoading = (state) => state.ocr.isLoading;
export const selectError = (state) => state.ocr.error;
export const selectFieldMapping = (state) => state.ocr.fieldMapping;
export const selectFieldDefinition = (state) => state.ocr.fieldDefinition;
export const selectOtherfieldDefinition = (state) => state.ocr.otherfieldDefinition;
export const selectEstimatedFields = (state) => state.ocr.estimatedFileds;

export const hasMissingFields = (state) => {
  const _fieldDefinition = selectFieldDefinition(state);
  const _fieldMapping = selectFieldMapping(state);
  const _estimatedFields = selectEstimatedFields(state);

  if (!_fieldDefinition || !_fieldMapping || !_estimatedFields) {
    return false;
  }

  /**
   * Return if there's any field without a value
   * or there's not an estimate about the field
   */
  const missingFields = _fieldDefinition.filter(
    (item, index) => !!_fieldMapping[item.field] && !!_estimatedFields[item.field]
  );
  return missingFields.length > 0;
}

// Actions
export const { setFileInfo, setTransactionId, setUploading, setOcrResult, setLoading, setError, setFieldMapping, unsetField, resetOcr } = ocrSlice.actions;

// Async thunk for polling
export const pollForOcrResults = (transactionId) => async (dispatch) => {
  if (!transactionId) {
    dispatch(setError("Transaction id not provided"));
    return;
  }

  dispatch(setLoading(true));

  try {
    const OCR_URL = process.env.REACT_APP_OCR_URL;
    const OCR_POLLING_INTERVAL = process.env.REACT_APP_OCR_POLLING_INTERVAL || 5000;

    // You could implement polling logic with setTimeout instead of setInterval
    // for better control with Redux
    const pollData = () => {
      return new Promise((resolve, reject) => {
        const checkStatus = async () => {
          try {
            const response = await fetch(
              `${OCR_URL}?transactionId=${encodeURIComponent(transactionId)}`
            );
            const data = await response.json();

            if (data.status === "success" || data.data) {
              resolve(data);
            } else if (data.status === "progress") {
              console.log("In progress...");
              setTimeout(checkStatus, OCR_POLLING_INTERVAL);
            } else if (data.status === "error") {
              reject(new Error(data.message));
            } else {
              reject(new Error("Unknown status"));
            }
          } catch (error) {
            reject(error);
          }
        };

        checkStatus();
      });
    };

    const data = await pollData();
    dispatch(setOcrResult(data.data.data));
    dispatch(setError(null))
    dispatch(setFieldMapping())
  } catch (error) {
    dispatch(setError(error.message));
  } finally {
    dispatch(setLoading(false));
  }
};

export default ocrSlice.reducer;
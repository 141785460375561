import filePlaceholder from "./icons/ocr-file-placeholder.svg";
import trashbinIcon from "./icons/ocr-trash.svg";

function humanFileSize(bytes, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) + r) >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
}

const progressStyles = {
  height: "8px",
  backgroundColor: "#1F6CF9",
  borderRadius: "320px",
  transition: "width 0.5s ease-in-out",
};

export default function OcrFileBox({
  inputFile,
  uploadProgress,
  onDeleteClick,
}) {
  if (!inputFile) {
    return <></>;
  }

  const canDelete = !!onDeleteClick && typeof onDeleteClick === "function";


  return (
    <div className="ocr-filebox p-3">
      <div className="d-flex flex-row justify-content-between align-items-center">
        {/* placeholder image */}
        <img
          src={filePlaceholder}
          alt="file-placeholder-icon"
          role="presentation"
        />
        {/* filename */}
        <div className="d-flex flex-column flex-grow-1 w-100">
          <span className="ocr-filebox-filename">
            {inputFile.name}
          </span>
          {/* size and uploading text */}
          <span className="ocr-filebox-filesize">
            {uploadProgress > 0 && uploadProgress < 100 && (
              <span style={{ color: "#1F6CF9" }}>Uploading...</span>
            )}
            &nbsp;{humanFileSize(inputFile.size)}
          </span>
        </div>
        {/* trashbin icon */}
        {canDelete && (
          <img
            src={trashbinIcon}
            id="trashbin-icon"
            alt="trashbin-placeholder-icon"
            role="presentation"
            onClick={(ev) => onDeleteClick(ev)}
          />
        )}
      </div>
      {/* progress */}
      {uploadProgress > 0 && uploadProgress < 100 && (
        <div className="ocr-filebox-progress-bar mt-2">
          <div style={{ ...progressStyles, width: `${uploadProgress}%` }}></div>
        </div>
      )}
    </div>
  );
}

import React, { useRef, useState } from "react";
import { Navigate } from "react-router-dom";

// OCR COMPONENTS
import OcrHeader from "./OcrHeader.jsx";
import OcrDropzone from "./OcrDropzone.jsx";
import { OcrSpinnerSection } from "./OcrCircularSpinner.jsx";
import OcrResultDataContainer from "./OcrResultData.jsx";
import documentIcon from './icons/ocr-document.svg'
import documentIconOrange from './icons/ocr-document-orange.svg'
import infoArrow from './icons/ocr-info-arrow-mobile.svg'

// DSY
import { DsyDivider } from "../../dsy-wrappers/dsy-divider.js";
// REDUX
import { useSelector, useDispatch } from "react-redux";
import {
  selectResult,
  selectIsLoading,
  pollForOcrResults,
  selectTransactionId,
  selectIsUploading,
  selectFieldMapping,
  selectFieldDefinition,
  selectEstimatedFields,
  hasMissingFields as _hasMissingFields
} from "./ocr-reducer.js";

import { get, set } from "../../reducers/data";

import InfoModal from "../form/info-button/InfoModalComponent.jsx";

import textCopy from './text-copy.js';


export default function OcrPage() {
  const main = useRef(null);
  const dispatch = useDispatch();
  // REDIRECT TO FORM
  const [shouldRedirect, setShouldRedirect] = useState(false);

  // Ocr state variables
  const transactionId = useSelector(selectTransactionId);
  const isUploading = useSelector(selectIsUploading);
  const ocrResults = useSelector(selectResult);
  const isLoading = useSelector(selectIsLoading);
  const fieldDefinition = useSelector(selectFieldDefinition);
  const fieldMapping = useSelector(selectFieldMapping);
  const estimatedFields = useSelector(selectEstimatedFields);
  const hasMissingFields = useSelector(_hasMissingFields)
  
  // altre variabili di ocr che non vengono utilizzate
  //const otherFields = useSelector(selectOtherfieldDefinition);
  // Data State Variables
  const isNegozi = useSelector(get)["negozi"];

  const text = textCopy[isNegozi ? "negozi" : "web"];

  const transformOcrData = async () => {
    const actions = [].concat(Object.keys(fieldMapping).map((key) => {
      return dispatch(set({ cell: key, value: fieldMapping[key] }));
    }))
    actions.concat(Object.keys(estimatedFields).map((key) => {
      return dispatch(set({ cell: key, value: estimatedFields[key] }));
    }))
    dispatch(actions)
  }

  //OCR MOBILE MODAL
  const [showModal, setShowModal] = useState(false);
  const ocrMobileModalTitle = "Ecco cosa ti aspetta durante la simulazione dei tuoi risparmi"
  const ocrMobileModalContent = String.raw`
    <div className="ocr-header-list-container">
        <ol className="ocr-header-list">
            <li>Ti chiederemo alcuni dati sulla tua casa, sulle fonti di energia che utilizzi e sui tuoi consumi.</li>
            <li>Analizzeremo questi dati per fornirti un report sulle tue opportunità di risparmio e proporti delle soluzioni che potrai configurare come credi sia meglio per te.</li>
            <li>Potrai lasciarci i tuoi contatti per ricevere il report e parlare con un nostro consulente per approfondire il tuo piano di risparmio.</li>
        </ol>
    </div>
  `
  const escKey = (event) => {
    if (event.key === "Escape")
      setShowModal(false);
    if (event.key === "") {
      setShowModal(!showModal)
    }
  }

  if (shouldRedirect) {
    ocrResults && transformOcrData();
    return isNegozi ? <Navigate to="/form-negozi" /> : <Navigate to="/form" />;
  }

  return (
    <div className="main d-flex row white-bg">
      <div className="col col-lg-4" style={{ backgroundColor: "#0047CC" }}>
        <OcrHeader />
      </div>
      <main
        ref={main}
        className="ocr-page d-flex col-lg-8 flex-column justify-content-space-between align-items-space-between"
      >
        {isLoading ? (
          <div className="ocr-retrieve-loading">
            <OcrSpinnerSection />
          </div>
        ) : (
          <div className="ocr-section p-lg-5">
            <div className="title">
              <h2
                className="title d-none d-lg-block d-flex flex-column w-100"
                level="h1"
                level-size="xl"
              >
                 {text.body.title}
              </h2>
            </div>

            {/* ONLY MOBILE */}
            <div className="ocr-info-section w-100 d-flex flex-row align-items-center justify-content-between d-lg-none mt-3">
              <div className="ocr-info-section-title">
                {text.body.title}
              </div>
              <div className="ocr-info-section-icon">
                {showModal && <InfoModal
                  title={ocrMobileModalTitle}
                  subtitle={ocrMobileModalContent} keyDown={(ev) => escKey(ev)} close={(ev) => setShowModal(false)} />
                }
                <img src={infoArrow} alt="Apri informazioni mobile" onClick={ev => setShowModal(true)} />
              </div>
            </div>

            {!ocrResults && (
              <div className="label-component">
                {text.body?.ocrLoader?.title && <h2 className="title">{text.body.ocrLoader.title}</h2> }
                <p className="subtitle" dangerouslySetInnerHTML={{__html: text.body.ocrLoader.subtitle}}></p>
              </div>
            )}

            <div className="d-flex flex-column w-100">
              <OcrDropzone />
            </div>

            {transactionId && !ocrResults && (
              <div className="d-flex flex-column mt-4 w-100">
                <button
                  className="dsy-button primary"
                  aria-label="Recupera i dati della bolletta"
                  onClick={() => dispatch(pollForOcrResults(transactionId))}
                  disabled={isUploading || !transactionId}
                >
                  Recupera i dati della bolletta
                </button>
              </div>
            )}

            {!ocrResults && (
              <div className="label-component d-flex flex-column w-100">
                <p className="subtitle" style={{ color: "black" }} dangerouslySetInnerHTML={{__html: text.body.ocrLoader.afterText}}></p>
              </div>
            )}

            {!ocrResults && <DsyDivider />}

            {!ocrResults && (
              <div className="label-component d-flex flex-column w-100">
                <h2 className="title">
                  {text.body.ocrNoBill.title}
                </h2>
                <p className="subtitle" dangerouslySetInnerHTML={{__html: text.body.ocrNoBill.text}}></p>
                <div className="d-flex flex-column">
                  <button
                    className="dsy-button secondary"
                    aria-label="Compila i dati manualmente"
                    onClick={() => setShouldRedirect(true)}
                  >
                    Compila i dati manualmente
                  </button>
                </div>
              </div>
            )}

            {(ocrResults && fieldDefinition) && (
              <div className="ocr-results">
                <div className="label-component d-flex flex-column w-100 mb-1 mt-4">
                  <h2 className="title">
                    {text.body.ocrResults.title}
                  </h2>
                  { text.body.ocrResults.subtitle && <p className="subtitle" dangerouslySetInnerHTML={{__html: text.body.ocrResults.subtitle}}></p> }

                  <div className="ocr-result-data">
                    <OcrResultDataContainer
                      items={fieldDefinition}
                      dataObject={ocrResults}
                    />
                  </div>
                  <p className="mt-2">
                    <span className="ocr-strong">Il simbolo</span> <img src={documentIcon} alt="document icon" role="presentation" /> indica i <span className="ocr-strong">dati</span> che abbiamo <span className="ocr-strong">recuperato</span> dalla tua bolletta
                    {isNegozi ? <>. Sottolinea che potete comunque verificare e modificare i dati recuperati.</> : <>, se necessario puoi verificarli e modificarli.</>}
                    
                    <br/><br/>
                    <span className="ocr-strong">Il simbolo</span> <img src={documentIconOrange} alt="document icon" role="presentation" /> di colore arancione indica i <span className="ocr-strong">dati</span> che sono stati <span className="ocr-strong">stimati dalle informazioni presenti in bolletta</span>
                    {isNegozi ? <>. Sottolinea che potete comunque verificare e modificare i dati stimati.</> : <>, se necessario puoi verificarli e modificarli.</>}
                  </p>
                </div>
              </div>
            )}
            {(ocrResults && hasMissingFields) && (
              <div className="ocr-results mt-3">
                <div className="label-component d-flex flex-column w-100 mt-1">
                  <h2 className="title">
                    Purtroppo alcuni dati ci sono sfuggiti
                  </h2>
                  <p className="subtitle">
                    Puoi caricare una bolletta diversa e riprovare, oppure
                    procedere e insere manualmente queste informazioni quando
                    verranno richieste.
                  </p>
                  <div className="ocr-result-data">
                    <OcrResultDataContainer
                      items={fieldDefinition}
                      dataObject={ocrResults}
                      positive={false}
                    />
                  </div>
                </div>
              </div>
            )}

            {/*  
              {(ocrResults && otherFields) && (
                <div className="ocr-results mt-3">
                  <div className="label-component d-flex flex-column w-100 mt-1">
                    <h2 className="title">
                      Questi sono degli altri dati che abbiamo recuperato per te
                    </h2>
                    <p className="subtitle">
                      Al momento non ci servono per la simulazione, ma potrebbero
                      tornare utili in futuro.
                    </p>
                    <div className="ocr-result-data">
                      <OcrResultDataContainer
                        items={otherFields}
                        dataObject={ocrResults}
                        positive={true}
                      />
                    </div>
                  </div>
                </div>
              )}
            */}
            {/*  
              {(ocrResults && otherFields) && (
                <div className="ocr-results mt-3">
                  <div className="label-component d-flex flex-column w-100 mt-1">
                    <h2 className="title">
                      Questi invece sono altri dati a disposizione.
                    </h2>
                    <p className="subtitle">
                      Non siamo riusciti a recuperarli e non vengono utilizzati
                    </p>
                    <div className="ocr-result-data">
                      <OcrResultDataContainer
                        items={otherFields}
                        dataObject={ocrResults}
                        positive={false}
                      />
                    </div>
                  </div>
                </div>
              )}
            */}

            {ocrResults && (
              <div className="d-flex flex-column mt-4 w-100">
                <button
                  className="dsy-button primary"
                  aria-label="Andiamo avanti con la simulazione"
                  onClick={() => setShouldRedirect(true)}
                  disabled={!ocrResults}
                >
                  Andiamo avanti con la simulazione
                </button>
              </div>
            )}
          </div>
        )}
      </main>
    </div>
  );
}

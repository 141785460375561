import React from 'react';
import { useState,useEffect } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import circle from "../../../icons/icon/finalthanks.svg"
import { get } from '../../../reducers/data';
import { useSelector } from 'react-redux';
import Header from './Header';

function WaitAndThanks({ loading, fs, title, main}) {
    const [index, setIndex] = useState(0)
    const messageRef = React.createRef();
    const data = useSelector(get);
    const negozi = data["negozi"]

    useEffect(()=>{
        messageRef.current.focus()
    })

    const text = {
        true: {
            true: "Attendi pochi secondi il simulatore sta creando il progetto su misura per te",
            false:"Attendi pochi secondi il simulatore sta stimando il tuo risparmio"
        },
        false: {
            true: "Fantastico il progetto è ora pronto e sarà inviato alla tua casella di posta elettronica",
            false: "Fantastico La valutazione è andata a buon fine" 
        }
    }

    return (<div id="thanks" className={"d-flex flex-column "+ (fs === true ? "fs" : "") }>
        {/* <div className="header">
            <a href="https://enel.it"><img src={logo} height={32} alt="Vai alla homepage di Enel Energia"/></a>
        </div> */}
        <div aria-live="polite" className="visually-hidden" aria-relevant="all" tabIndex={0} ref={messageRef} role="banner">
            {text[loading === true][fs === true]}
        </div>
        { loading ? <Container className="d-flex flex-column justify-content-center align-items-center">
        <main ref={main}>
        
            <Spinner id="spinner" className='' size={"bg"} />
          
            <div className="title">
                 {title || (negozi ? "Attendere ancora qualche secondo " : "Attendi ancora qualche secondo") }
            </div>

            {!negozi &&   <p className="subtitle text-center">
            Immagina quanto potrebbe brillare la tua casa con un tocco di energia extra!
            </p>}
          
            </main>
        </Container> :
        <Container className="d-flex flex-column justify-content-center align-items-center">
        <img src={circle} height="66px" alt="loading spinner" />
        <div className="title">
            Fantastico!
        </div>
        <p className="subtitle text-center">
        Abbiamo calcolato il risparmio che puoi ottenere con soluzioni più efficienti.
        </p>
  
    </Container> }

    
    </div>);
}

export default WaitAndThanks;
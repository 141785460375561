import cn from "classnames";
import documentIcon from './icons/ocr-document.svg'
import documentIconOrange from './icons/ocr-document-orange.svg'
import { useSelector } from "react-redux";
import { selectFieldMapping, selectEstimatedFields } from "./ocr-reducer.js";

export default function OcrFieldNote({field=null, style = {}, extraClass=""}) {

    const fieldMapping = useSelector(selectFieldMapping);
    const estimatedFields = useSelector(selectEstimatedFields);

    if (!!fieldMapping[field]) {

        return (
            <div className={cn("ocr-field-note d-flex flex-row align-items-center justify-content-start", extraClass)} style={style}>
                <img src={documentIcon} alt="icona documento recuperato da bolletta"/>
                <span className="ocr-field-note-text">
                    Recuperato dalla bolletta
                </span>
            </div>
        )
    
    } else if (!!estimatedFields[field]) {

        return (
            <div className={cn("ocr-field-note d-flex flex-row align-items-center justify-content-start", extraClass)} style={style}>
                <img src={documentIconOrange} alt="icona documento stimato da bolletta"/>
                <span className="ocr-field-note-text">
                    Stimato dalla bolletta
                </span>
            </div>
        )

    } else {
        return <></>
    }
}
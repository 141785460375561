import React from 'react';
import electricMeter from "../../../icons/icon/electric-meter.svg"
import infoFilled from "../../../icons/icon/info_filled.svg"
import casa from "../../../icons/real_estate_agent.svg"
import { useDispatch, useSelector } from 'react-redux'
import { get, set,  reset } from '../../../reducers/data'

function WarningBoxComponent({icon, extraClass, content }) {
    const data = useSelector(get)

    if (icon === "casa" &&  data["C15"] > 1 && parseInt(data["C13"]) < 42)
     return (
        <div className={"warning-box d-flex justify-content-center align-items-center " + extraClass} >
            <div className="me-3">
                {icon === "casa" && <img src={casa} alt="" role="presentation" /> }
                {icon === "info" && <img src={infoFilled} alt="" role="presentation" /> }
                {icon === undefined  && <img src={electricMeter} alt="" role="presentation" /> }
                {/* <DsyIcon name={icon} color="blue"  size="24px"  />  */}
            </div>
            <div>
                {content}
            </div>
        </div>
        
    );
    

}

export default WarningBoxComponent;
import React, { useState} from 'react';
import TV_Pic from "../../../icons/products2024/TV_Pic.png"

import FotoVirtuale from "../../../icons/icon/new/FotoVoltaicoVirtuale.png"
import Pompa from "../../../icons/icon/pompa.png"
import { useSelector } from 'react-redux';
import {
    get
} from '../../../reducers/data';
import FotoRinghiera from "../../../icons/products2024/Foto_Ringhiera.png"
import FotoMuratura from "../../../icons/products2024/Foto_Muratura.png"
import { Row, Col } from 'react-bootstrap';
import ProductModalComponent from './ProductModal';
import zoom_in from '../../../icons/zoom_in.svg';
import Frigo_75 from "../../../icons/icon/new/Frigo_75.png"
import Frigo_Bespoke from "../../../icons/icon/new/Frigo_Bespoke.png"
import Foto_Batteria from "../../../icons/icon/new/Foto_Batteria.png"
import Foto_Solare from "../../../icons/icon/new/Foto_Solare.png"
import expand_less from "../../../icons/expand_less.svg"
import expand_more from "../../../icons/expand_more.svg"
import check_blue from "../../../icons/check_blue.svg"
import Piastra60 from "../../../icons/products2024/Piastra_60cm.png";
import Piastra60DF from "../../../icons/products2024/Piastra_60cm_DF.png";
import Lavatrice1 from "../../../icons/products2024/Lavatrice1.png";
import Lavatrice2 from "../../../icons/products2024/Lavatrice2.png";

const images = {
    TV_Pic,
    FotoVirtuale,
    Pompa,
    FotoRinghiera,
    FotoMuratura,
    Frigo_75, 
    Frigo_Bespoke, 
    Foto_Batteria, 
    Foto_Solare,
    Piastra60,
    Piastra60DF,
    Lavatrice1,
    Lavatrice2        
}

const Number = ({ big, comma }) => {
    return <div className="d-flex price-text justify-content-end mb-2"> 
        <p className="big"> {big} </p>
        <div className="d-flex flex-column small justify-content-between">
            <p className="f22 mb-0">,{comma} €*</p>
            <p className="f16 mb-1"> IVA Inclusa</p>
        </div>
    </div>
}

const FotoV = ({ big, comma }) => {
    return <div className="d-flex price-text justify-content-end mb-2"> 
        <p className="big"> {big} </p>
        <div className="d-flex flex-column small justify-content-between">
            <p className="f22 mb-0"></p>
            <p className="f16 mb-1">{comma}</p>
        </div>
    </div>
}

const ProductBox = ({ 
    title, serie, description, image, selected, 
    tags, topChildren, extraTitleCell, children, 
    tipo, action, bottom }) => {
    const data = useSelector(get);
    let extraTitle = ""
    const [show, setShow] = useState(false);
    const [showDesc, setShowDesc] = useState(false); 
    const negozi = data["negozi"] ?? false;
    
    if (title?.includes("Plug")) { 
        if (data["TipologiaBalconi"] === "Muratura"){
            image = "FotoMuratura"
            // serie = "Fotovoltaico da parete / balcone "
            // topChildren.tipo = "Fotovoltaico da parete"
                 serie ="Fotovoltaico da appartamento"
            topChildren.tipo = "Fotovoltaico da appartamento"
        }
        else {
            image = "FotoRinghiera"
            // serie = "Fotovoltaico da balcone con ringhiera"
            // topChildren.tipo = "Fotovoltaico da balcone"
            serie ="Fotovoltaico da appartamento"
            topChildren.tipo = "Fotovoltaico da appartamento"
        }
    }
    const isNegozi = data["negozi"]
    if (!topChildren && !tipo){
        topChildren = isNegozi ?
        {big: "15", comma: "99", totale: "383,76", tan: "TAN 0% - TAEG 0%", tipo: "Televisione" } : 
        {big: "17", comma: "23", totale: "413,52", tan: "TAN 7.50% - TAEG 7.71%", tipo: "Televisione" }
}
    if (extraTitleCell && extraTitleCell === "User_TV") {
        topChildren = isNegozi ?
        {big: "15", comma: "99", totale: "383,76", tan: "TAN 0% - TAEG 0%", tipo: "Televisione" } : 
        {big: "17", comma: "23", totale: "413,52", tan: "TAN 7.50% - TAEG 7.71%", tipo: "Televisione" }
}
    if (extraTitleCell && data[extraTitleCell] && extraTitleCell === "User_TV") {
        extraTitle = data[extraTitleCell].split(" ").pop() 
        if (extraTitle === "43\"") {
            topChildren = isNegozi ?
                {big: "15", comma: "99", totale: "383,76", tan: "TAN 0% - TAEG 0%", tipo: "Televisione" } : 
                {big: "17", comma: "23", totale: "413,52", tan: "TAN 7.50% - TAEG 7.71%", tipo: "Televisione" }
        }
        
        if (extraTitle === "55\"") {
            topChildren = isNegozi ?
                {big: "20", comma: "99", totale: "503,76", tan: "TAN 0% - TAEG 0%", tipo: "Televisione" } :
                {big: "22", comma: "61", totale: "542,64", tan: "TAN 7.50% - TAEG 7.71%", tipo: "Televisione" }
        }
        if (extraTitle === "65\"") {
            topChildren = isNegozi ?
                {big: "28", comma: "99", totale: "695,76", tan: "TAN 0% - TAEG 0%", tipo: "Televisione" } :
                {big: "31", comma: "23", totale: "749,52", tan: "TAN 7.50% - TAEG 7.70%", tipo: "Televisione" }
        }
    } 

    return (
        <>
        <Row className={"redesign-product-box " + (selected ? "selected" : "")}>
            <Col sm={12} lg={4} >
             <div className="product-image">
                <img  src={images[image]} alt={title} role="presentation" />
                </div>
            </Col>
            <Col sm={12} lg={8} >
                <p className="serie mt-2 mt-lg-0" dangerouslySetInnerHTML={{ __html: serie}}></p>
                <h2 className="title"> <b> {title} {extraTitle}</b></h2>
                {(!negozi || (negozi && showDesc)) && 
                <p className="description f16" dangerouslySetInnerHTML={{ __html: description}}></p>}
                {negozi && <div className="d-none d-lg-block f12" style={{ color: "#D3135A", cursor: "pointer"}} onClick={() => setShowDesc(!showDesc)}>  
                    { !showDesc ? <>Mostra descrizione prodotto <img src={expand_more} description="" role="presentation" />  </>  : <>Nascondi descrizione prodotto <img src={expand_less} description="" role="presentation" /></> } </div>}
                { tags && <ul style={{ listStyleType: "none", padding: 0, marginTop: "0rem"}}>
                    {tags.map((tag, index) => <li key={index}> <img src={check_blue} alt="" role="presentation" /> <b className="f14"> {tag}</b></li> )}
                </ul>}

                <div className="d-lg-none mt-4 scopri" onClick={() => setShow(true)}> Scopri di più sul prodotto <img src={zoom_in} alt="" role="presentation" /> </div>
            </Col>
          
        {topChildren?.extra && <div  className="my-3">  </div>}
            {(children || topChildren) &&<Col className="mx-12 mt-3" style={{ position:"relative"}}>
              {topChildren?.extra && <div  className="extra"> {topChildren.extra} </div>}
                <Row className="price-box">
                    <Col sm={3} lg={6} > <b className="f12">Prezzo {topChildren ? topChildren.tipo : tipo}</b> </Col>
                    <Col sm={9} lg={6} className="text-end" >
                     {topChildren && <>
                        {topChildren.topCopy ?  <p className="mb-0 f12">{topChildren.topCopy}</p> :  <p className="mb-0 f12"> <b>24 rate</b> mensili da </p>}
                       
                        {<Number {...topChildren} />}
                        <p className="f14 mb-2"> <b> {topChildren.tan} </b></p>
                        {topChildren.totale && <p className="f12 mb-0">Totale: {topChildren.totale} €</p>}
                    </>}
                    {children}
                    </Col>
                </Row>
            </Col>}
            {bottom && bottom.map((x, i) =><Col sm={12} className="mt-2 mx-12 w-100" key={i}> 
            <Row className="price-box mt-3" >
                    <Col sm={3} lg={6} > <b className="f12">{x.title}</b> </Col>
                    <Col sm={9} lg={6} className="text-end" >
                        <FotoV {...x} />
                        {x.sub && <p className="f14 mb-0"> <b> {x.sub} </b></p>}
                        <p className="f12 mb-0">IVA inclusa</p>
                    </Col>
                </Row>
                </Col>)
            }
            {action && <div className="px-3 mt-3">
                      {action} 
        </div>}
        </Row>
      
        <ProductModalComponent 
            show={show}
            chiudi={() => setShow(false)}
            extraTitle={title}
            serie={serie}
            tags={tags}
            description={description}
            image={images[image]}
        />
         </>
    );
};

export default ProductBox;
import React, { useState} from 'react'

import { useSelector, useDispatch } from 'react-redux';
import { set, get } from '../../../reducers/data';

import * as animations from "../../../animations"

import handlePage0 from './handlePage0';
import handlePage1 from './handlePage1';
import handlePage2 from './handlePage2';
import handlePage3 from './handlePage3';
import handlePage4 from './handlePage4';
import handlePage5 from './handlePage5';
import handlePage9 from './handlePage9';
import GriLottie from '../../dsy-inhouse/GriLottiePlayer';
import { usePressObserver } from '../../../custom-hooks/usePressObserver';

export default function BannerImageComponent({extraClass, pageTitle, imageId}) {
  const data = useSelector(get);
  const [animationRunning, setAnimationRunnning] = useState(true) 
  const dispatch = useDispatch();
  let toRender = []
  const escPressed = usePressObserver({ watchKey: "Escape" })
  
  if(escPressed){
      dispatch(set({ cell: "play", value: false }))
  }
  
  if (imageId === "casa-magica") { 
    return (
      <div id="banner-image" className={extraClass}>
        <GriLottie animationData={animations.casaMagica} />
      </div>
    )
  }

  if (pageTitle === "La potenza del tuo contatore" || pageTitle === "La potenza del suo contatore") {
    const potenza = handlePage9(data)
    return (
      <div id="banner-image" className={extraClass}>
        {potenza.map((x,i) =>  { return {...x, key: i }})}
      </div>
    )
  }
  
  switch(data["pageIndex"] || 0){
    case 0: 
      toRender = handlePage0(data)
    break;
    case 1:
      toRender = handlePage1(data)
    break;
    case 2:
      toRender = handlePage2(data)
    break;
    case 3:
      toRender = handlePage3(data)
    break;
    case 4:
      toRender = handlePage4(data)
    break;
    case 5:
      // toRender = handlePage9(data)
      // break;
    case 6:
    case 7:
    case 8:
      toRender = handlePage5(data, pageTitle)
    break;
    // case 9:
    //   toRender = handlePage9(data)
    //   break;
    case 100:
      toRender = [ <GriLottie play={animationRunning} style={{top: "-20%"}} animationData={animations.fuochiArtificio} /> , ...handlePage0(data)]
    break;
    default:
      toRender = []
  }

  return (
    <div id="banner-image" className={extraClass}>
        {toRender.map((x,i) =>  { return {...x, key: i }})}
    </div>
  )
}

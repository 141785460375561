import * as cn from 'classnames';

import ocrPath from "./icons/ocr-path.svg"
import logo from "../../icons/storepos.svg"
import textCopy from './text-copy.js';

import { useSelector } from "react-redux";
import { get } from "../../reducers/data";


import useBreakpoint from "../../custom-hooks/useBreakpoint.js";

function OcrHeader({ title, subtitle, page, className, hideSubtitleMobile, anchor, main, imageId}) {

    const bp = useBreakpoint()
    const isDesktop = (bp ==="xxl" || bp ==="xl" || bp ==="lg")

    const isNegozi = useSelector(get)["negozi"];

    const text = textCopy[isNegozi ? "negozi" : "web"];

    return (<>
        <header id="form-header" className="ocr-header">
        
            <div id="tondo" className="d-none d-lg-block"></div>
            
            <div className={cn('title-box','p-md-4','d-flex', 'flex-column')}>
                {/* LOGO */}
                <div>
                    <a href="https://enel.it" id="enel-logo" target="_blank" rel="noreferrer" >
                        <img src={logo} className="logo" alt="Vai alla homepage di Enel Energia" />
                    </a>
                </div>

                {
                    isDesktop && <>
                        <div className="title">
                            <h2 className="d-block" level="h1" level-size="xl">
                                {text.header.title}
                            </h2>
                        </div>

                        <div className="ocr-header-list-container">
                            <ol className="ocr-header-list">
                                {text.header.list.map((item, index) => 
                                    <li key={index} dangerouslySetInnerHTML={{__html: item}}></li>
                                )}
                            </ol>
                        </div>

                        <img src={ocrPath} alt="ocr" role="presentation" className="ocr-header-image" />
                    </>
                }

                {
                    !isDesktop && <>
                        <div className="d-flex flex-row align-items-start justify-content-start">
                            <div className="title">
                                <h2 className="d-block" level="h1" level-size="xl">
                                   {text.body.title}
                                </h2>
                            </div>


                            <img src={ocrPath} alt="ocr" role="presentation" className="ocr-header-image" />
                        </div>
                    </>
                }

                
              
            </div>
        </header>
     </>);
}

export default OcrHeader;
import React, { useState, useEffect } from "react";

// Separate Spinner Component
const CircularSpinner = ({ size = "6rem" }) => {
  return (
    <div className="ocr-circular-spinner" style={{ width: size, height: size }}>
      {/* Gray circle (background) */}
      <div className="ocr-circular-spinner-track"></div>

      {/* Blue rotating arc */}
      <div className="ocr-circular-spinner-arc">
        <svg viewBox="0 0 100 100">
          <path
            d="M 50,50 m 0,-46 a 46,46 0 0 1 46,46 a 46,46 0 0 1 -46,46 a 46,46 0 0 1 -46,-46 a 46,46 0 0 1 46,-46"
            stroke="transparent"
            fill="none"
          />
          <path
            d="M 50,50 m 0,-46 a 46,46 0 0 1 46,46"
            stroke="#0d6efd"
            strokeWidth="8"
            strokeLinecap="round"
            fill="none"
          />
        </svg>
      </div>
    </div>
  );
};

export const OcrSpinnerSection = () => {
  return (
    <div className="container d-flex flex-column justify-content-center align-items-center min-vh-100">
      <div className="row">
        <div className="col text-center">
          <div className="d-flex justify-content-center mb-4">
            <CircularSpinner size="6rem" />
          </div>

          <h1 className="fw-bold text-dark mb-3">
            Stiamo recuperando i tuoi dati ...
          </h1>

          <p className="fs-5 text-secondary">
            Ci vogliono circa 60 secondi di attesa.
          </p>
        </div>
      </div>
    </div>
  );
};

import {useState} from "react";
import { Button, ButtonGroup, Col, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { get } from "../../../reducers/data";
import ThanksComponent from "../../Thanks"
import iconRiscaldamento from "../../../icons/icon/heating.svg";
import iconAcquaCalda from "../../../icons/icon/water.svg";
import iconFotovoltaico from "../../../icons/icon/solare.svg";
import iconCotturaCibi from "../../../icons/icon/cooking.svg";

import iconFrigo from "../../../icons/icon/new/kitchen_r.svg"
import iconAsciugatrice from "../../../icons/icon/new/dry_cleaning_r.svg";
import iconLavatrice from "../../../icons/icon/new/laundry_r.svg"
import iconTV from "../../../icons/icon/new/tv_r.svg"
import BlueBoxComponent from "./BlueBoxComponent";
// import { MigrationContentSwitcher } from "../../dsy-wrappers/dsy-migration-content-switcher";

function RisparmioBox({
  title,
  description,
  icon,
  attuale,
  futura,
  risparmio,
  rispPercentuale,
  fonte
  }) {

  const risparmioPercentuale = rispPercentuale ?? (futura && attuale && Math.abs(Math.floor((parseFloat(attuale) - parseFloat(futura)) / attuale * 100)) + "%");
  const onlyIntegerEuroFormat = (number) => { 
    if (Number.isNaN(number)) return number;
    return (Number.parseFloat(number)?.toFixed(0)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + " €").replace("-", "");
  }
  return (
    <div className="risparmio-box pb-3 d-md-flex justify-content-md-center align-items-md-center mt-3">
      <div className="d-flex col-12 col-md-6 col-lg-6 pe-md-4 top align-items-center">
        <div className="img-container">
          <img src={icon} role="presentation" alt="" />
        </div>
        <div>
          <h2 className="title mb-1">{title}</h2>
          <div className="description f14" dangerouslySetInnerHTML={{ __html: description.replace("[FONTE]", fonte?.toLowerCase())}}></div>
        </div>
      </div>
      <div className="risparmio-details d-flex justify-content-between col-12 col-md-6 col-lg-6">
        <div className="actual-futura">
        {attuale && (
          <div className="attuale">
            <div className="mb-2 f14 bold">Stima spesa annua</div>
            <div className="d-flex justify-content-between">
              <div>
                <div className="f12">Spesa attuale</div>
                <div className="nowrap bold s f18">{onlyIntegerEuroFormat(attuale)}</div>
              </div>
              <div>
                <div className="f12">Spesa futura minima</div>
                <div className="nowrap bold f18">{onlyIntegerEuroFormat(futura)}</div>
              </div>
            </div>
          </div>
        )}
        </div>
          <div className={"risparmio f14 " + (title === "Fotovoltaico" ? " no-border" : "") }>
            <div className="mb-2 bold">Risparmio annuale fino a</div>
            <div className="g f18" >&nbsp;
              {risparmioPercentuale && <span>-{risparmioPercentuale}</span>}
              </div>
            <div className="b nowrap f18">-{onlyIntegerEuroFormat(risparmio)}</div>
          </div>
      </div>
    </div>
  );
}



function RisparmioBoxTotal({
  title,
  description,
  icon,
  attuale,
  futura,
  risparmio,
  rispPercentuale,
  fonte
  }) {

  const risparmioPercentuale = rispPercentuale ?? (futura && attuale && Math.abs(Math.floor((attuale - futura) / futura * 100)) + "%");
  const onlyIntegerEuroFormat = (number) => { 
    if (Number.isNaN(number)) return number;
    return Number.parseFloat(number)?.toFixed(0)?.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + " €";
  }
  return (
    <div className="risparmio-box risparmio-box-total d-md-flex justify-content-md-center align-items-md-center ">
      <div className="d-flex col-12 col-md-6 col-lg-6 pe-md-4 top">
        <div>
          <h2 className="title my-0 mb-1">{title}</h2>
          <p className="description f14" dangerouslySetInnerHTML={{ __html: description.replace("[FONTE]", fonte?.toLowerCase())}}></p>
        </div>
      </div>
      <div className="risparmio-details d-flex justify-content-between col-12 col-md-6 col-lg-6">
        <div className="actual-futura">
        {attuale && (
          <div className="attuale f14">
            <div className="mb-2">Stima spesa annua</div>
            <div className="d-flex justify-content-between">
              <div >
                <div>Spesa attuale</div>
                <div className="s nowrap g f18"><b>{onlyIntegerEuroFormat(futura)}</b></div>
              </div>
              <div>
                <div>Spesa futura minima</div>
                <div className="nowrap g f18"><b>{onlyIntegerEuroFormat(attuale)}</b></div>
              </div>
            </div>
          </div>
        )}
        </div>
          <div className={"risparmio f14 " + (title === "Fotovoltaico" ? " no-border" : "") }>
            <div className="mb-2">Risparmio annuo fino a</div>
            <div className="green f18">
             -{risparmioPercentuale ?? "-10%"}
            </div> 
            <div className="b nowrap f18">{onlyIntegerEuroFormat(risparmio)}</div>
          </div>
      </div>
    </div>
  );
}

const TabletSpacing =() => <div id="tablet-spacing" className="d-block d-lg-none">
  <div className="spacer my-32"></div>
  <div className="mb-32 text-center text-md-left">Aumenta ulteriormente il tuo risparmio annuo aggiungendo un impianto fotovoltaico</div>
</div>


function RisparmioPreliminareComponent() {
  const data = useSelector(get);
  const [showRiepilogo, setShowRiepilogo] = useState(false);
  const [showWarning, setShowWarning] = useState(true);

  let foto_risp = parseFloat(data["Foto_Risparmio"]) < 0 ? parseFloat(data["Foto_Risparmio"]) : 0
  let risc_risp = parseFloat(data["Risc_Risparmio"]) < 0 ? parseFloat(data["Risc_Risparmio"]) : 0
  let totRisp = foto_risp + risc_risp

  const buttons = []
  const fotoSi = data["Foto_Risparmio"] && data["Foto_Perc"] &&  data["Foto_Risparmio"] != 0
  if (fotoSi) buttons.push("Fotovoltaico")

  const fontiSi = data["Risc_Risparmio"]?.startsWith("-") || data["AC_Risparmio"]?.startsWith("-") || data["Cott_Risparmio"]?.startsWith("-")
  if (fontiSi) buttons.push("Fonti energetiche")
    
  const elettrodomesticiSi = data["Frigorifero_Risparmio"] || data["Lavatrice_Risparmio"] || data["TV_Risparmio"] || data["Asciugatrice_Risparmio"]
  if (elettrodomesticiSi) buttons.push("Elettrodomestici")

  const [selected, setSelected] = useState(buttons[0]);

  // console.log(foto_risp, risc_risp, data["Foto_Risparmio"], data["Risc_Risparmio"], totRisp)
  if (data["RisparmioAnnuo"].startsWith("0"))
    return <div id="thanks-fs" className="max-eff"><ThanksComponent error="Il livello di efficienza energetica della tua casa parla chiaro: hai fatto un lavoro straordinario! I tuoi sforzi hanno portato a risultati eccellenti, la tua casa è moderna e gestisce al meglio i consumi energetici. Come sempre, c'è margine di miglioramento! Dai un’occhiata al nostro sito per scoprire cos’altro puoi fare." /></div> 

  if (data["SpesaAnnuaAttuale"] === null || (totRisp === 0 && (fotoSi === false && fontiSi === false && elettrodomesticiSi === false)))
    return <div id="thanks-fs" className="max-eff"><ThanksComponent error="Il livello di efficienza energetica della tua casa parla chiaro: hai fatto un lavoro straordinario! I tuoi sforzi hanno portato a risultati eccellenti, la tua casa è moderna e gestisce al meglio i consumi energetici. Come sempre, c'è margine di miglioramento! Dai un’occhiata al nostro sito per scoprire cos’altro puoi fare." /></div> 

const Title = (negozi) => {
  if (negozi) return <>
    <div className="bold-title"> 
    Abbiamo preparato una stima del risparmio che il cliente potrebbe ottenere sui suoi consumi!
      </div>
      <div className="gen-subtitle mb-3">
      Svela come gli interventi di ammodernamento e innovazione, che riducono i consumi, possano fare una grande differenza per il suo portafoglio e per l'ambiente!
      </div></>
  else return <>
    <div className="bold-title"> 
      Abbiamo preparato una stima di quanto potresti risparmiare sui tuoi consumi!
      </div>
      <div className="gen-subtitle mb-3">
        Scopri come gli interventi di ammodernamento e innovazione che riducono i consumi possono fare una grande differenza per il tuo portafoglio e per l'ambiente! 
      </div></>
}

const SecondTitle = (negozi) => {
  if (negozi) return <>
   <div className="bold-title mt-4">
   Ecco come può aumentare il suo risparmio energetico
        </div>
        <div className="gen-subtitle mb-3">
        Le soluzioni che mostriamo al cliente qui sotto rappresentano la miglior offerta in termini di rapporto qualità/prezzo, che potrà comunque modificare e personalizzare nei prossimi passaggi. La stima del suo risparmio sopra indicata è stata calcolata sulla base di tali soluzioni.
        </div>
      </>
else return <>
   <div className="bold-title mt-4">
          Ecco come puoi aumentare il tuo risparmio energetico
        </div>
        <div className="gen-subtitle mb-3">
        Le soluzioni che ti mostriamo qui sotto rappresentano la miglior offerta in termini di rapporto qualità/prezzo, che potrai comunque modificare e personalizzare nei prossimi passaggi. La stima del tuo risparmio sopra indicata è stata calcolata sulla base di tali soluzioni
        </div>
        </>
}

const negozi = data["negozi"]
const avverbio = negozi ? "sua" : "tua"
const avverbioM = negozi ? "suo" : "tuo"  

  return (
    <div id="risparmio-preliminare">
        
        <Title negozi={data["negozi"]} />
    
       <RisparmioBoxTotal 
        title={negozi ? 'Stima del risparmio del cliente' : `Stima del tuo risparmio`}
        description={negozi ? "Spiega al cliente che i vantaggi economici e i risparmi energetici riportati in questo report rappresentano una stima basata sugli interventi di efficienza energetica proposti. Precisa che <b> tali risparmi saranno conseguibili solo dopo l'implementazione completa degli interventi e il pagamento dei relativi costi.</b>" : "I vantaggi economici e i risparmi energetici riportati in questo report rappresentano una stima basata sugli interventi di efficienza energetica proposti. Si precisa che <b>tali risparmi saranno conseguibili solo dopo l'implementazione completa degli interventi e il pagamento dei relativi costi.</b>"}
        futura={data["SpesaAnnuaAttuale"].replace(".",",") ?? "STUB"}
        attuale={data["SpesaAnnuaFutura"].replace(".",",") ?? "STUB"}
        rispPercentuale={(parseFloat(data["RisparmioPerc"], 1) * 100).toFixed(0) + "%" }
        risparmio={data["RisparmioAnnuo"].replace(".",",") }//  data["RisparmioAnnuo"].replace(".",",") ?? "STUB"
        />

        <SecondTitle negozi={data["negozi"]} />
      
        <ButtonGroup className='buttons gri-buttons' role="tablist">
              {buttons.map((key, index) => (
                <Button role="tab"
                  aria-selected={key === selected}
                  key={index}
                  variant='outline-primary'
                  style={{ zIndex: 0 }}
                  className={key === selected ? 'active' : ''}
                  onClick={() => setSelected(key)}
                >
                  {key.replace("Acqua", "acqua").replace("Clima", "Riscaldamento").replace("Induzione", "Cottura Cibi")}
                </Button>
              ))}
            </ButtonGroup>

        { data["negozi"] && <BlueBoxComponent nocenter={true} extraClassName={"no-border justify-content-start my-4 px-3"}> 
              <b className="f12">Sottolinea al cliente di scegliere l'elettrico per proteggersi dagli aumenti dei costi del gas!</b>
          </BlueBoxComponent> 
           }
      
        {/* <MigrationContentSwitcher 
          entries={buttons.map(key => ({key, label: key}))}
          onClick={({ key }) => setSelected(key)}
          preselectedContentKey={selected} /> */}

      { selected === "Fotovoltaico" && fotoSi &&<RisparmioBox
        title="Fotovoltaico"
        description={`Soluzione economica e conveniente per <b>produrre energia elettrica per la ${avverbio} casa</b>`}
        icon={iconFotovoltaico}
        // rispPercentuale={(Math.abs(parseFloat(data["Foto_Perc"]) * 100))?.toFixed(0) + "%"}
        risparmio={data["Foto_Risparmio"] ?? "STUB"}
      />}

      { selected === "Fonti energetiche" && fontiSi &&<>

      { data["Risc_Risparmio"]?.startsWith("-") && 
        <RisparmioBox
          fonte={data["C25"]}
          title="Riscaldamento"
          description={data["C24"] === true || data["C24"] === "Si" ? "Sganciandosi dall'impianto centralizzato ed utilizzando le <b>pompe di calore</b>" : `Sostituendo la ${avverbio} caldaia a [FONTE] con una <b>pompa di calore</b>`}
          icon={iconRiscaldamento}
          attuale={data["Risc_Attuale"] ?? "STUB"}
          futura={data["Risc_Futura"] ?? "STUB"}
          risparmio={data["Risc_Risparmio"] ?? "STUB"}
        />}

       { data["AC_Risparmio"]?.startsWith("-") &&<RisparmioBox
        fonte={data["C26"]}
        title="Acqua calda"
        description={`Sostituendo la ${avverbio} caldaia a [FONTE] con una <b>pompa di calore</b>`}
        icon={iconAcquaCalda}
        attuale={data["AC_Attuale"] ?? "STUB"}
        futura={data["AC_Futura"] ?? "STUB"}
        risparmio={data["AC_Risparmio"] ?? "STUB"}
      /> }
    { data["Cott_Risparmio"]?.startsWith("-") &&<RisparmioBox
            fonte={data["C27"]}
            title="Cottura cibi"
            description={`Sostituendo il ${avverbioM} piano cottura a [FONTE] con un <b>piano cottura a induzione</b>`}
            icon={iconCotturaCibi}
            attuale={data["Cott_Attuale"] ?? "STUB"}
            futura={data["Cott_Futura"] ?? "STUB"}
            risparmio={data["Cott_Risparmio"] ?? "STUB"}
          /> }  

      </> }

    { selected === "Elettrodomestici" && elettrodomesticiSi &&<>
    { data["Frigorifero_Risparmio"] &&<RisparmioBox
        fonte={data["C27"]}
        title="Frigorifero"
        description={`Sostituendo il ${avverbioM} frigorifero con un <b>equivalente più moderno</b>`}
        icon={iconFrigo}
        attuale={data["Frigorifero_Attuale"] ?? "STUB"}
        futura={data["Frigorifero_Futura"] ?? "STUB"}
        risparmio={data["Frigorifero_Risparmio"] ?? "STUB"}
      /> } 

    { data["Lavatrice_Risparmio"] &&<RisparmioBox
            title="Lavatrice"
            description={`Sostituendo la ${avverbio} lavatrice con una <b>equivalente più moderna</b>`}
            icon={iconLavatrice}
            attuale={data["Lavatrice_Attuale"] ?? "STUB"}
            futura={data["Lavatrice_Futura"] ?? "STUB"}
            risparmio={data["Lavatrice_Risparmio"] ?? "STUB"}
          /> } 

    { data["TV_Risparmio"] &&<RisparmioBox
            title="TV"
            description={`Sostituendo la ${avverbio} televisione con un <b>equivalente più moderno</b>`}
            icon={iconTV}
            attuale={data["TV_Attuale"] ?? "STUB"}
            futura={data["TV_Futura"] ?? "STUB"}
            risparmio={data["TV_Risparmio"] ?? "STUB"}
          /> } 

    { data["Asciugatrice_Risparmio"] &&<RisparmioBox
            title="Asciugatrice"
            description={`Sostituendo la ${avverbio} asciugatrice con un <b>equivalente più moderno</b>`}
            icon={iconAsciugatrice}
            attuale={data["Asciugatrice_Attuale"] ?? "STUB"}
            futura={data["Asciugatrice_Futura"] ?? "STUB"}
            risparmio={data["Asciugatrice_Risparmio"] ?? "STUB"}
          /> } 

    </> }
  


        {/* <div className="mt-5 small text-center d-md-none">
          Aumenta ulteriormente il tuo risparmio annuo aggiungendo un impianto fotovoltaico
        </div> */}

      {/* <div className="d-lg-flex justify-content-end mt-md-4 risparmio-buttons">
        <div className="me-lg-2 d-none">
            <RiepilogoModalComponent show={showRiepilogo} close={() => setShowRiepilogo(false)} />
            <Button className="outline mt-xl-0 mb-xl-0 mt-4 mb-3" onClick={() => showRiepilogo(true)}>RIVEDI DATI INSERITI</Button>
          </div>
             <Button className="outline mt-3">RIVEDI DATI INSERITI</Button>
        <div>
          <a style={{ width: "100%"}} href={data["ProjectUrl"]} target="_blank" rel="noreferrer" >
            <Button className="outline mb-1">SCARICA LA SIMULAZIONE</Button>
          </a>
        </div>
      </div>
       <div className="mt-2 mb-5 small text-center text-lg-end d-lg-none">
                Scarica il report della simulazione in formato PDF
        </div> */}


        {/* <Button className="filled mt-3">CONTINUA</Button> */}

        <div className="d-lg-none my-3 f14">
        Se continui potrai personalizzare le soluzioni che abbiamo proposto e scoprire i costi degli interventi.
        </div>
    </div>
  );
}

export default RisparmioPreliminareComponent;

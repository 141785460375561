import React from "react"
import { DsyIcon } from "../../dsy-wrappers"


export function GriButton({ label, variant, children, disabled, onClick, fullWidth, icon, id, className, library, iconPosition = "left", iconColor="red"}) {
    let cName = "dsy-button " + (variant ?? "primary")
    if (fullWidth)
      cName += " w-100"
    if (className)
      cName += " " + className
    
    return (<button id={id ?? ""} className={cName} disabled={disabled} aria-label={label} onClick={onClick}
    onKeyDown={(e) => {if (e.key === "Enter" || e.key ==="Space") onClick()}}>
          { icon ? <span aria-hidden={true} className="d-flex justify-content-center align-items-center">
                      {iconPosition === "left" && <DsyIcon library={ library ?? "system" } className="me-2" name={icon}></DsyIcon>}
                      {children} 
                      {iconPosition === "right" && <DsyIcon library={ library ?? "system" } className="ms-2" name={icon}></DsyIcon>}
                  </span>
          : children}  
    </button>)
  } 

                    //   icon-side="left" 
                    // icon-name="thin-arrow-left-secondary"  
import {boxes} from './page3Images'

export default function handlePage5(data, pageTitle) {
    switch(pageTitle){
        case "Televisore": 
        if (data["Final_TV"] === "Si") 
            return [<img src={boxes.grey.tv} className="banner-box-grande" alt="" role="presentation" />]
        else 
            return [<img src={boxes.big.tv} className="banner-box-grande" alt="" role="presentation" />]
        case "Frigorifero":
            return [<img src={boxes.big.fridge} className="banner-box-grande" alt="" role="presentation" />]
        case "Cottura cibi": 
        return [<img src={boxes.big.cook} className="banner-box-grande" alt="" role="presentation" />]
        
            case "Lavatrice":
            return [<img src={boxes.big.wash} className="banner-box-grande" alt="" role="presentation" />]
        case "Asciugatrice":
            return [<img src={boxes.big.dry} className="banner-box-grande" alt="" role="presentation" />]
        case "Riscaldamento":
            return [<img src={boxes.big.fire} className="banner-box-grande" alt="" role="presentation" />]
        case "Autoproduzione energia":
        case "Solare Virtuale":
        case "Fotovoltaico":
        case "Energia solare":
                return [<img src={boxes.big.sun} className="banner-box-grande" alt="" role="presentation" />]
        case "Potenza contatore":
        default: 
            return []
    }
}
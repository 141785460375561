import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector, useDispatch } from "react-redux";
import { get } from "../../reducers/data";
import {
  setFileInfo,
  setTransactionId,
  setUploading,
  setError,
  selectFileInfo,
  selectTransactionId,
  selectIsLoading,
  selectError,
  pollForOcrResults,
  selectIsUploading,
  resetOcr,
} from "./ocr-reducer.js";

import uploadIcon from "./icons/ocr-upload.svg";
import OcrFileBox from "./OcrFileBox";

import textCopy from './text-copy.js';

const OCR_URL = process.env.REACT_APP_OCR_URL;

export default function OcrDropzone({ onOcrRetrieve }) {
  const dispatch = useDispatch();
  const fileInfo = useSelector(selectFileInfo);
  const transactionId = useSelector(selectTransactionId)
  const error = useSelector(selectError);
  const isUploading = useSelector(selectIsUploading);

  // Reference for progress simulation interval
  const progressIntervalRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);

  const isNegozi = useSelector(get)["negozi"];
  const text = textCopy[isNegozi ? "negozi" : "web"];

  // Clean up interval on component unmount
  useEffect(() => {
    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, []);

  // Function to simulate upload progress
  const simulateProgress = useCallback(() => {
    setUploadProgress(0);

    // Clear any existing interval
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }

    // Start a new interval to simulate progress
    progressIntervalRef.current = setInterval(() => {
      setUploadProgress((prev) => {
        // Slow down as we approach 90%
        if (prev < 30) return prev + 5;
        if (prev < 60) return prev + 3;
        if (prev < 80) return prev + 1;
        if (prev < 90) return prev + 0.5;
        // Stop at 90% - the actual completion will bump to 100%
        return 90;
      });
    }, 300);
  }, []);

  // Function to stop progress simulation
  const stopProgressSimulation = useCallback(() => {
    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
      progressIntervalRef.current = null;
    }
  }, []);

  const uploadFile = useCallback(
    async (fileToUpload) => {
      dispatch(setError(null));
      dispatch(setTransactionId(null))
      dispatch(setUploading(true));

      const formData = new FormData();
      formData.append("file", fileToUpload);

      const fileInfo = {
        name: fileToUpload.name,
        size: fileToUpload.size,
        type: fileToUpload.type,
        lastModified: fileToUpload.lastModified,
      };

      dispatch(setFileInfo(fileInfo));

      // Start simulating progress
      simulateProgress();
      try {
        const response = await fetch(OCR_URL, {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Upload failed with status: ${response.status}`);
        }

        const data = await response.json();
        const parsedData = typeof data === "string" ? JSON.parse(data) : data;

        // Stop progress simulation and set to 100%
        stopProgressSimulation();
        setUploadProgress(100);

        // Store only serializable data in Redux
        dispatch(setTransactionId(parsedData.properties.transactionId));

        // Reset progress after a short delay
        setTimeout(() => {
          setUploadProgress(0);
        }, 500);
      } catch (err) {
        console.error("Error:", err.message);
        stopProgressSimulation();
        setUploadProgress(0);
        dispatch(setError(err.message));
      } finally {
        dispatch(setUploading(false));
      }
    },
    [dispatch, simulateProgress, stopProgressSimulation]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      const fileToUpload = acceptedFiles[0];
      if (fileToUpload) {
        uploadFile(fileToUpload);
      }
    },
    [uploadFile]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    maxSize: 15 * 1024 * 1024, // 15MB in bytes
  });

  return (
    <div className="ocr-dropzone-container d-flex flex-column">
      {fileInfo ? (
        <OcrFileBox
          inputFile={fileInfo}
          uploadProgress={uploadProgress}
          onDeleteClick={() => dispatch(resetOcr())}
        />
      ) : (
        <div {...getRootProps()} className="ocr-dropzone">
          <input {...getInputProps()} />
          <div className="ocr-dropzone-area d-flex flex-column align-item-center justify-content-start">
            <img
              src={uploadIcon}
              alt="upload-icon"
              role="presentation"
              height="35px"
            />
            <p className="ocr-dropzone-area-text text-center my-2">
              {text.body.ocrDropzone.text}{" "}
              <span className="click-text">clicca qui</span>
            </p>
            <p className="ocr-dropzone-area-file-info text-center">
              File supportati: JPG, PNG, PDF
              <br />
              Max: 15MB
            </p>
          </div>
        </div>
      )}

      {error && <div className="text-danger mt-2">Errore: {error}</div>}
    </div>
  );
}

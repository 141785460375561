import React from "react";
import cn from "classnames";
import documentIcon from './icons/ocr-document.svg'
import documentIconRed from './icons/ocr-document-red.svg'
import documentIconOrange from './icons/ocr-document-orange.svg'

import { useSelector } from "react-redux";
import {
  selectEstimatedFields,
  selectFieldDefinition,
  selectResult
} from "./ocr-reducer.js";
// Document icon SVG component to replace react-feather

// Individual InfoChip component (the blue rounded chip)
const OcrInfoChip = ({ title, value, unit, state = 'positive' }) => {

  const icon = {
    "positive": documentIcon,
    "negative": documentIconRed,
    "estimated": documentIconOrange,
  }

  return (
    <div
      className={cn(
        "ocr-result-chips bg-light rounded-pill d-inline-flex align-items-center py-1 px-2 my-1",
        {
          "ocr-result-chips-positive": state === "positive",
          "ocr-result-chips-negative": state === "negative",
          "ocr-result-chips-estimated": state === "estimated",
        }
      )}
    >
      <img src={icon[state]} alt="document icon"/>
      <div className="d-flex flex-column flex-md-row align-items-md-center">
        <span className="me-md-2">
          {title}
          {value && ":"}
        </span>
        {value && <span className="ocr-strong">{value}&nbsp;{unit}</span>}
      </div>
    </div>
  );
};

// Main component that renders multiple InfoChips
const OcrResultDataContainer = ({ positive=true }) => {

  const dataObject = useSelector(selectResult);
  const items = useSelector(selectFieldDefinition);
  const estimated = useSelector(selectEstimatedFields);

  return (
    <div className="d-flex flex-wrap">
      {items
        .filter(
          (item, index) => positive ? (!!dataObject[item.key] && dataObject[item.key] !== "") || !!estimated[item.field] : !dataObject[item.key] && !estimated[item.field]
        )
        .sort((a, b) => a.order - b.order)
        .map((item, index) => {
          const state = !!estimated[item.field] ? "estimated" : (!!dataObject[item.key] ? "positive" : "negative");
          const value = state == "estimated" ? estimated[item.field] : dataObject[item.key];
          return (<OcrInfoChip
            key={index}
            title={item.title}
            value={value}
            unit={item.unit}
            state={state} />)
          }
        )}
    </div>
  );
};

export default OcrResultDataContainer;

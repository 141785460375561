import { useDispatch, useSelector } from 'react-redux'
import { set, get } from '../../../reducers/data'
import React, { useState } from 'react'
import euro from '../../../icons/icon/europeryear.svg'
import FacSimileCopyComponent from '../fac-simile/FacSimileCopy'
import DisclaimerComponent from '../reusable/DisclaimerComponent'
import { GriCheckboxComponent } from '../../dsy-inhouse/GriCheckboxComponent'
import OcrFieldNote from '../../ocr/OcrFieldNote'


function PercentageComponent ({ name, cell, canBeNull, after, subtitle }) {
  const dispatch = useDispatch()
  const data = useSelector(get)
  const [aggiornamento, setAggiornamento] = React.useState("")
  const currentValue = data[cell]
  const riscAmbiente = data['C25']
  const riscAcqua = data['C26']
  const pianoCottura = data['C27']
  const fieldsTotal = [
    {
      name: 'Elettrico',
      cell: 'C33'
    },
    {
      name: riscAmbiente,
      cell: 'F25'
    },
    {
      name: riscAcqua,
      cell: 'F26'
    },
    {
      name: pianoCottura,
      cell: 'F27'
    }
  ]
  const fields = []
  for (let field of fieldsTotal) {
    if (!field.name) continue
    if (!fields.map(x => x.name).includes(field.name)) fields.push(field)
  }

  const el = fields.shift()
  fields.push(el)
  return (
    <div
      className={
        'percentage-component d-flex flex-column justify-content-center ' +
        (data ? 'filled' : '')
      }
    >
      {fields.map((x, i) => (
        <div key={i} className="position-relative">
          <div className={(data[x.cell] !== undefined ? "active " : "" ) + 'input-border-half ' + (x.name === "Elettrico" ? "": "mb-16") + (currentValue === 'No' ? " dis" : "")  } key={i}>
            {data[x.cell] && <label htmlForm={"id-"+x.name} className="prop-name" aria-hidden={true}>{x.name}</label> }
            <input 
              id={"id-"+x.name}
              className="prop-value"
              placeholder={x.name}
              aria-roledescription='Textbox'
              alt={"Inserisci spesa annuale per " + x.name }
              min={0}
              type='number'
              value={data[x.cell] ?? ''}
              disabled={currentValue === 'No'}
              onInput={(e) => {
                if (e.nativeEvent.data === null && e.nativeEvent.inputType !== "deleteContentBackward") { e.preventDefault(); return }
                if (e.nativeEvent.data === "." || e.nativeEvent.data === "e" || e.nativeEvent.data === "-") { e.preventDefault(); return }
                setAggiornamento("Valore per " + x.name + " aggiornato a " + e.target.value + " euro")
                dispatch(
                  set({
                    cell: x.cell,
                    value: e.target.value
                  })
                )
                dispatch(
                  set({
                    cell: cell,
                    value: 'Si'
                  })
                )     
              }
            }
            onBlur={e => {
              if (e.target.value.startsWith("-"))
                e.target.value = e.target.value.replaceAll("-", "")
                dispatch(
                  set({
                    cell: x.cell,
                    value: e.target.value
                  })
                )
            }}
            />
            <img src={euro} alt='' role="presentation" height={38} width={48} aria-hidden={true} />
          </div>
          <OcrFieldNote field={x.cell} 
            extraClass="d-none d-xl-block" 
            style={{
              "right":"0", 
              "top":"5px", 
              "display": "block !important",
              "position": "absolute",
          }}/>
          <OcrFieldNote field={x.cell} extraClass="d-block d-xl-none mt-2" />
          {x.name === 'Elettrico' && <div className="subtitle mb-16">Senza canone RAI</div>}
        </div>
      ))}
      <div id="liveRegion" aria-live="polite" aria-atomic="true" className="visually-hidden">{aggiornamento}</div>
      {after && <p className='disclaimer'>{after}</p>}

      {data["negozi"] ?  <>
       <FacSimileCopyComponent 
       content="spesaAnnua"
       title="Insieme al cliente, cercate di individuare i dati necessari sulla sua bolletta" 
       subtitle={""}
       html={`<div >
                           <b> Pagina 3 della bolletta</b> <br />
                           Se non riesci a trovare le informazioni, consulta la <b><a aria-label="Leggi la guida ufficiale in pdf sul sito Enel Energia, il collegamento apre in una nuova scheda" rel="noreferrer" href="https://www.enel.it/content/dam/enel-it/documenti-supporto/tutto-sulla-bolletta/documenti/guida-bolletta-enel-energia.pdf" target="_blank"> guida alla lettura della bolletta</a></b>        
                   </div>`}
       image={1} />
       <DisclaimerComponent
         title="Il cliente non ha portato la bolletta e non conosce la sua spesa?"
         subtitle={"In questo caso, seleziona l’opzione “Non lo sa” per saltare questo passaggio. Il sistema utilizzerà una stima basata sulle informazioni relative all’abitazione del cliente."}
       />
       </>:  <>
       <FacSimileCopyComponent 
       content="spesaAnnua"
       title="Ecco come puoi recuperare questa informazione" 
       subtitle={"Nell'esempio qui sotto ti mostriamo dove trovare questo dato."}
       html={`<div >
                           <b> Pagina 3 della bolletta</b> <br />
                           Se non riesci a trovare le informazioni, consulta la <b><a aria-label="Leggi la guida ufficiale in pdf sul sito Enel Energia, il collegamento apre in una nuova scheda" rel="noreferrer" href="https://www.enel.it/content/dam/enel-it/documenti-supporto/tutto-sulla-bolletta/documenti/guida-bolletta-enel-energia.pdf" target="_blank"> guida alla lettura della bolletta</a></b>        
                   </div>`}
       image={1} />
       <DisclaimerComponent
         title="Se non sai la spesa, ci pensiamo noi!"
         subtitle={"Se non riesci a trovare tutte le informazioni sulla tua spesa, puoi selezionare l’opzione “Non lo so” e saltare questo passaggio. Utilizzeremo una stima basata sulle informazioni relative all’abitazione."}
       /></>}
     
      {canBeNull && (
        <div className='d-flex d-row mb-16'>
          <GriCheckboxComponent onChange={e =>  {
            const goingToSi = currentValue === "No"
              dispatch(
                set({ cell: cell, value: currentValue === 'No' ? 'Si' : 'No' })
              )
              for (let i = 0; i < fields.length; i++) {
                dispatch(set({ cell: fields[i].cell, value: undefined }))
              }
              if (goingToSi === true) setAggiornamento("Hai selezionato di non indicare la spesa annuale per le fonti energetiche")
              }
            }
            id={"non-la-conosco"}
            checked={currentValue === 'No'} 
            ariaLabel={"Checkbox per non indicare la spesa annuale per le fonti energetiche"} />
          <label htmlFor="non-la-conosco" style={{ marginLeft: '20px', cursor: 'pointer' }} aria-hidden={true}

          //TODO: FORSE RIABILITA
      //          onClick={(e) => {
      //     const goingToSi = currentValue === "No"
      //       dispatch(
      //         set({ cell: cell, value: currentValue === 'No' ? 'Si' : 'No' })
      //       )
      //       for (let i = 0; i < fields.length; i++) {
      //         dispatch(set({ cell: fields[i].cell, value: undefined }))
      //       }
      //       if (goingToSi) setAggiornamento("Hai selezionato di non indicare la spesa annuale per le fonti energetiche")
      //  }}
          >
            {data["negozi"] ? "Non lo sa" : "Non lo so"} 
          </label>
        </div>
      )}
    </div>
  )
}

export default PercentageComponent
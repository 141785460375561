import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { GriButton } from '../../dsy-inhouse/GriButtonComponent';

const WarningID = () => {
    return (
        <Modal id="blocking-warning" show={true} backdrop="static" keyboard={false} centered={true}>
            <Modal.Header>
                <Modal.Title>Attenzione!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            Non è consentito procedere. È necessario ripartire da X-Customer per accedere ad Enel Lumiè
            </Modal.Body>
            <Modal.Footer>
                <a href="https://enelxconsumer.enel.it/partnersso" target="_blank">
                    <GriButton>Vai a X-Customer</GriButton>
                </a>
                {/* <Button variant="secondary" disabled>
                    Close
                </Button> */}
            </Modal.Footer>
        </Modal>
    );
};

export default WarningID;